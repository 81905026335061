/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import equals from 'fast-deep-equal';
import { produce } from 'immer';
import noop from 'lodash/noop';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { Text } from '@oui/app-core/src/components/Text';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import { WebTooltip } from '@oui/app-core/src/components/WebTooltip';
import { useTheme } from '@oui/app-core/src/styles';
import { reorderArrayItem } from '@oui/lib/src/reorderArrayItem';
import {
  CrisisTimelineEvent,
  CrisisTimeline as CrisisTimelineType,
} from '@oui/lib/src/types/avro/crisisTimeline';

import { Icon } from '../components/Icon';
import { getColorForX, getXPositionsForItems } from '../components/RiskCurve';

type Item = CrisisTimelineEvent;

// Let's us know if this item was added by pressing "enter"
// In that case we want to autoFocus & select so it's easy to overwrite
const NEW_ITEM_TEXT = 'New item';

const getItemStyle = (_isDragging: boolean, draggableStyle?: object) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none' as const,
  // background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});

const getListStyle = (_isDraggingOver: boolean) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: 8,
  position: 'relative' as const,
  overflow: 'auto',
  maxHeight: 350,
});

function CrisisTimelineItem({
  isDragging,
  isEditing,
  isHovered,
  isCrisisPeak,
  item,
  onCancelEditing,
  onChangeValue,
  onToggleCrisisPeak,
  onRemove,
  onSubmitEditing,
  provided,
  riskCurveColor,
  testID,
}: {
  isCrisisPeak: boolean;
  isDragging?: boolean;
  isEditing?: boolean;
  isHovered?: boolean;
  item: Item;
  onCancelEditing: () => void;
  onChangeValue: (item: Item) => void;
  onToggleCrisisPeak: () => void;
  onRemove: () => void;
  onSubmitEditing: () => void;
  provided: DraggableProvided;
  riskCurveColor?: string;
  testID: string;
}) {
  const { theme } = useTheme();
  return (
    <View
      row
      style={[
        { padding: 8, borderRadius: 10 },
        isHovered ? { backgroundColor: theme.color.gray800 } : null,
      ]}
      testID={testID}
    >
      <div {...provided.dragHandleProps} data-testid={`${testID}_dragHandle`}>
        <View style={{ width: 20, alignItems: 'center', justifyContent: 'center', height: 20 }}>
          {isDragging || isHovered ? (
            <Icon
              name="reorder"
              color={theme.color.primary100}
              testID={`${testID}_dragHandleIcon`}
            />
          ) : (
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: riskCurveColor ?? '#DEE0E5',
              }}
            />
          )}
        </View>
      </div>
      {isEditing ? (
        <View style={{ marginLeft: 15, paddingTop: 1 }} flex={1}>
          <TextInput
            testID={`${testID}_input`}
            autoFocus
            selection={
              item.text === NEW_ITEM_TEXT ? { start: 0, end: NEW_ITEM_TEXT.length } : undefined
            }
            value={item.text}
            onChangeValue={(newText) => {
              onChangeValue({ ...item, text: newText });
            }}
            onBlur={onCancelEditing}
            inputStyle={[
              { paddingVertical: 8 },
              isCrisisPeak ? { fontFamily: 'OpenSansBold' } : null,
            ]}
            onSubmitEditing={onSubmitEditing}
          />
        </View>
      ) : (
        <View
          flex={1}
          style={{
            marginLeft: 32,
            minHeight: 46,
          }}
          row
          spacing={8}
        >
          <Text
            text={item.text}
            size={14}
            style={[
              item.isWarningSign
                ? {
                    textDecorationColor: '#ECBF2D',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                  }
                : null,
            ]}
            weight={isCrisisPeak ? 'bold' : undefined}
          />
          {item.isWarningSign ? (
            <WebTooltip label="Marked warning signs only visible to you">
              <Icon name="warning" color="#ECBF2D" size={14} />
            </WebTooltip>
          ) : null}
        </View>
      )}
      {isEditing || isHovered ? (
        <View row spacing={12} style={{ marginLeft: 16 }}>
          <WebTooltip label="Mark as warning sign">
            <Pressable
              style={[
                item.isWarningSign
                  ? {
                      borderRadius: 10,
                      padding: 5,
                      margin: -5,
                      backgroundColor: theme.color.primary100,
                    }
                  : null,
                { cursor: 'pointer' } as any,
              ]}
              onPress={() => {
                onChangeValue({ ...item, isWarningSign: !item.isWarningSign });
              }}
              testID={`${testID}_warningSignToggle`}
            >
              <Icon name="warning" color={item.isWarningSign ? 'white' : theme.color.primary100} />
            </Pressable>
          </WebTooltip>
          <WebTooltip label="Mark as crisis peak">
            <Pressable
              style={[
                isCrisisPeak
                  ? {
                      borderRadius: 10,
                      padding: 5,
                      margin: -5,
                      backgroundColor: theme.color.primary100,
                    }
                  : null,
                { cursor: 'pointer' } as any,
              ]}
              onPress={() => {
                onToggleCrisisPeak();
              }}
              testID={`${testID}_crisisPeakToggle`}
            >
              <Icon name="crisis-peak" color={isCrisisPeak ? 'white' : theme.color.primary100} />
            </Pressable>
          </WebTooltip>
          <WebTooltip label="Delete">
            <Icon
              name="bin"
              onPress={onRemove}
              color={theme.color.primary100}
              aria-label="Delete"
              testID={`${testID}_deleteButton`}
            />
          </WebTooltip>
        </View>
      ) : null}
    </View>
  );
}

function CrisisTimelineDraggableItem({
  autoFocus,
  index,
  isDraggingAnItem,
  item,
  isCrisisPeak,
  onChangeValue,
  onToggleCrisisPeak,
  onRemove,
  onSubmitEditing,
  riskCurveColor,
  testID,
}: {
  autoFocus?: boolean;
  index: number;
  isDraggingAnItem: boolean;
  isCrisisPeak: boolean;
  item: Item;
  onChangeValue: (item: Item) => void;
  onToggleCrisisPeak: () => void;
  onRemove: () => void;
  onSubmitEditing: () => void;
  riskCurveColor?: string;
  testID: string;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(autoFocus);

  return (
    <Draggable key={item.ID} draggableId={item.ID} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsEditing(true)}
          {...provided.draggableProps}
          // {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <CrisisTimelineItem
            testID={testID}
            riskCurveColor={riskCurveColor}
            onCancelEditing={() => setIsEditing(false)}
            onChangeValue={onChangeValue}
            onToggleCrisisPeak={onToggleCrisisPeak}
            onRemove={onRemove}
            onSubmitEditing={onSubmitEditing}
            item={item}
            isCrisisPeak={isCrisisPeak}
            isDragging={false}
            isEditing={isEditing}
            isHovered={isHovered && !isDraggingAnItem}
            provided={provided}
          />
        </div>
      )}
    </Draggable>
  );
}

export function CrisisTimeline({
  onChangeValue,
  showRiskColor,
  value,
}: {
  onChangeValue: (value: CrisisTimelineType) => void;
  showRiskColor: boolean;
  value: CrisisTimelineType;
}) {
  // TODO we dont handle an edge case where value is updated after this component is mounted.
  // In this case we'll show stale `data`. However this is probably pretty rare and should only happen
  // if the component is mounted while an update mutation is in flight
  const [data, setData] = useState(value);
  const [isDragging, setIsDragging] = useState(false);

  const [debouncedValue] = useDebounce(
    data,
    // 500 is long enough to prevent saving on every keystroke/action but not so long that we
    // have a large risk of unsaved data
    500,
    { maxWait: 5000, equalityFn: equals },
  );

  const xPositions = getXPositionsForItems(data);

  // If we have any changes pending save when the component is unmounting, we save them now
  const unmountRef = useRef({ value, data });
  unmountRef.current = { value, data };
  useEffect(() => {
    return () => {
      if (!equals(unmountRef.current.value, unmountRef.current.data)) {
        onChangeValue(unmountRef.current.data);
      }
    };
  }, [onChangeValue]);

  useEffect(() => {
    if (!equals(value, debouncedValue)) {
      onChangeValue(debouncedValue);
    } else if (debouncedValue.timeline.length === 0) {
      setData({
        crisisPeakID: debouncedValue.crisisPeakID,
        timeline: [
          {
            ID: uuid(),
            text: '',
            isWarningSign: false,
          },
        ],
      });
    }
    // we don't want to call onChangeValue when value changes, so we don't want this effect
    // to depend on it, however, don't want to call onChangeValue if the values are equal
    // eslint-disable-next-line
  }, [debouncedValue, onChangeValue]);

  const onDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);
  const onDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragging(false);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      setData({
        ...data,
        timeline: reorderArrayItem(data.timeline, result.source.index, result.destination.index),
      });
    },
    [data, setData],
  );

  return (
    <View testID="CrisisTimeline">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided, snapshot, rubric) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <CrisisTimelineItem
                testID="CrisisTimeline_itemClone"
                isCrisisPeak={data.timeline[rubric.source.index].ID === data.crisisPeakID}
                onToggleCrisisPeak={noop}
                onChangeValue={noop}
                onRemove={noop}
                onCancelEditing={noop}
                onSubmitEditing={noop}
                item={data.timeline[rubric.source.index]}
                provided={provided}
                isDragging={true}
                isEditing={false} // TODO choose these values based on actual state
                isHovered={false}
              />
            </div>
          )}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div style={{ position: 'relative' }}>
                <View
                  style={{
                    position: 'absolute',
                    width: 1,
                    top: 32,
                    left: 4 + 14,
                    bottom: 32,
                    backgroundColor: '#DEE0E5',
                  }}
                />
                {data.timeline.map((item, index) => (
                  <CrisisTimelineDraggableItem
                    testID={`CrisisTimeline_item_${index}`}
                    autoFocus={
                      (item.text === '' && index === data.timeline.length - 1) ||
                      item.text === NEW_ITEM_TEXT
                    }
                    item={item}
                    index={index}
                    key={item.ID}
                    isCrisisPeak={item.ID === data.crisisPeakID}
                    isDraggingAnItem={isDragging}
                    onToggleCrisisPeak={() => {
                      if (data.crisisPeakID === item.ID) {
                        setData({ ...data, crisisPeakID: '' });
                      } else {
                        setData({ ...data, crisisPeakID: item.ID });
                      }
                    }}
                    onChangeValue={(newItem) => {
                      setData(
                        produce(data, (draft) => {
                          draft.timeline[index] = newItem;
                        }),
                      );
                    }}
                    onRemove={() => {
                      setData({ ...data, timeline: data.timeline.filter((i) => i.ID !== item.ID) });
                    }}
                    onSubmitEditing={() => {
                      setData((curr) =>
                        produce(curr, (draft) => {
                          draft.timeline.splice(index + 1, 0, {
                            ID: uuid(),
                            text: NEW_ITEM_TEXT,
                            isWarningSign: false,
                          });
                        }),
                      );
                    }}
                    riskCurveColor={
                      showRiskColor ? getColorForX({ x: xPositions[index] }) : undefined
                    }
                  />
                ))}
                {provided.placeholder}
              </div>
              <View style={{ marginLeft: 6 }}>
                <Button
                  variant="text"
                  text="Add item"
                  icon="plus"
                  onPress={() => {
                    setData({
                      ...data,
                      timeline: [...data.timeline, { ID: uuid(), text: '', isWarningSign: false }],
                    });
                  }}
                  testID="CrisisTimeline_addItemButton"
                />
              </View>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </View>
  );
}
