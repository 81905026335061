import AsyncStorage from '@react-native-async-storage/async-storage';
import { HeaderBackButton } from '@react-navigation/elements';
import * as Sentry from '@sentry/core';
import * as Device from 'expo-device';
import * as Location from 'expo-location';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, Platform, StatusBar, TouchableOpacity } from 'react-native';
import MapView, { Marker } from 'react-native-maps';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button } from '@oui/app-core/src/components/Button';
import { PlacesTypeahead } from '@oui/app-core/src/components/PlacesTypeahead';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { pauseAppState } from '@oui/app-core/src/hooks/useAppState';
import { getApproximateLocation } from '@oui/app-core/src/lib/getApproximateLocation';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { Shadow, useTheme } from '@oui/app-core/src/styles';
import { SocialDistractionPlace } from '@oui/lib/src/types/avro/socialDistractions';

import DARK_MAP_STYLE from '@src/assets/google-maps-dark-style.json';
import MAP_STYLE from '@src/assets/google-maps-style.json';
import { ContactListItem } from '@src/components/Contact';
import Divider from '@src/components/Divider';
import { Icon } from '@src/components/Icon';
import { StackScreenProps } from '@src/types';

type Place = SocialDistractionPlace;

/*
 * For use on android or iOS.
 */
export function LocationPicker(props: { onChoose: (places: Place[]) => void; label: string }) {
  const [places, setPlaces] = useState<Place[]>([]);
  const { theme, scheme } = useTheme();
  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);
  const mapRef = useRef<MapView>(null);
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  const [isFocused, setIsFocused] = useState(false);
  // When keyboard is open, we need to make sure that the space taken up by the Visual component
  // doesn't prevent the Chat from moving up high enough to avoid the keyboard.
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  useEffect(() => {
    if (Platform.OS === 'web') {
      Sentry.captureMessage('LocationPicker used on web but is not web compatible');
    }

    function _keyboardDidShow() {
      setIsKeyboardOpen(true);
    }
    function _keyboardDidHide() {
      setIsKeyboardOpen(false);
    }

    const showListener = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    const hideListener = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);

    return () => {
      showListener.remove();
      hideListener.remove();
    };
  }, []);

  useEffect(() => {
    async function loadLocation() {
      // For some reason setting { location: 'inuse' } in detox setup isn't
      // actually granting us the permission and we can't interact with the system modal
      // if it appears so don't try to get the permission
      if (!global.e2e && Device.isDevice) {
        const status = await pauseAppState(async () => {
          await AsyncStorage.setItem('lastSeen', Date.now().toString());
          let { status: getStatus, canAskAgain } =
            await Location.requestForegroundPermissionsAsync();
          // If "while in use" permission is granted, we don't want to ask the user for full permissions
          // again, otherwise it's really annoying
          const askAgain = getStatus !== 'granted' && canAskAgain;
          const finalResult = await (askAgain
            ? Location.requestPermissionsAsync()
            : Promise.resolve({ status: getStatus }));
          return finalResult.status;
        });
        if (status === 'granted') {
          const location = await Location.getCurrentPositionAsync({});
          setCurrentLocation({ lat: location.coords.latitude, lng: location.coords.longitude });
        } else {
          const approxLocation = await getApproximateLocation();
          if (approxLocation) {
            setCurrentLocation(approxLocation);
          }
        }
      } else {
        const approxLocation = await getApproximateLocation();
        if (approxLocation) {
          setCurrentLocation(approxLocation);
        }
      }
    }
    loadLocation().catch(Sentry.captureException);
  }, []);

  useEffect(() => {
    const currentLatLng = {
      latitude: currentLocation?.lat ?? 0,
      longitude: currentLocation?.lng ?? 0,
    };
    const placeLatLngs = places.map((p) => ({ latitude: p.latitude, longitude: p.longitude }));

    // fixes issue on android where setting camera/fitToCoordinates doesn't work on mount
    const timeout = setTimeout(() => {
      if (places.length) {
        const latlngs = places.length > 2 ? placeLatLngs : [currentLatLng, ...placeLatLngs];
        mapRef.current?.fitToCoordinates(latlngs, {
          animated: true,
          edgePadding: { top: 20, right: 20, left: 20, bottom: 20 },
        });
      } else {
        mapRef.current?.setCamera({
          center: currentLatLng,
          zoom: 12,
        });
      }
    }, 50);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentLocation, places]);

  function onChoose() {
    props.onChoose(places);
  }

  return (
    <View style={{ flex: 1, paddingTop: insets.top, paddingBottom: insets.bottom }}>
      <StatusBar barStyle="dark-content" />
      <MapView
        onPress={Keyboard.dismiss}
        ref={mapRef}
        cacheEnabled={Platform.OS === 'ios'} // breaks map updating on android
        customMapStyle={scheme === 'dark' ? DARK_MAP_STYLE : MAP_STYLE}
        provider="google"
        rotateEnabled={false}
        scrollEnabled={false}
        style={{ height: isKeyboardOpen ? 100 : 240, width: '100%' }}
        toolbarEnabled={false}
        zoomControlEnabled={false}
        zoomEnabled={false}
        zoomTapEnabled={false}
      >
        {places.map((place) => (
          <Marker
            key={place.ID}
            coordinate={{
              latitude: place.latitude,
              longitude: place.longitude,
            }}
          >
            <View
              style={[
                Shadow.default,
                {
                  shadowRadius: 1,
                  width: 20,
                  height: 20,
                  backgroundColor: theme.color.primary100,
                  borderRadius: 10,
                  borderWidth: 3,
                  borderColor: 'white',
                },
              ]}
            />
          </Marker>
        ))}
      </MapView>
      <View style={{ paddingHorizontal: 20, marginTop: -10, zIndex: 3 }}>
        <PlacesTypeahead
          testID="LocationPicker_typehead"
          attribution={false}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={$t({ id: 'LocationPicker_search_placeholder', defaultMessage: 'Search' })}
          onSelect={(place) => {
            setPlaces((curr) => [...curr, place]);
          }}
          location={currentLocation ?? undefined}
        />
      </View>
      {isFocused && Platform.OS === 'android' ? (
        <TouchableOpacity activeOpacity={1} style={{ flex: 1 }} onPress={Keyboard.dismiss}>
          <View />
        </TouchableOpacity>
      ) : (
        <View
          flex={1}
          style={{
            paddingHorizontal: 20,
            zIndex: 1,
          }}
        >
          <Text
            text={props.label ?? $t({ id: 'LocationPicker_label', defaultMessage: 'Places' })}
            weight="semibold"
            style={{ marginVertical: 16 }}
            role="heading"
          />
          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingBottom: 10 }}>
            {(Platform.OS === 'android' && (isKeyboardOpen || isFocused) ? [] : places).map(
              (place) => {
                return (
                  <View key={place.ID}>
                    <View row style={{ justifyContent: 'space-between' }}>
                      <View flex={1}>
                        <ContactListItem
                          name={{ first: place.name, last: null }}
                          imageSize={40}
                          nameWeight="normal"
                        />
                      </View>
                      <Icon
                        aria-label="Delete place"
                        size={14}
                        color={theme.color.gray400}
                        name="close"
                        onPress={() => {
                          setPlaces((curr) => curr.filter((p) => p.ID !== place.ID));
                        }}
                      />
                    </View>
                    <Divider />
                  </View>
                );
              },
            )}
            <View row spacing={12}>
              <View
                row
                style={{
                  borderRadius: 10,
                  backgroundColor: theme.color.gray700,
                  padding: 10,
                }}
              >
                <Icon name="plus" color={theme.color.gray500} />
              </View>
              <Text
                text={$t({
                  id: 'LocationPicker_contentPlaceholder',
                  defaultMessage: 'Search for a place',
                })}
                color={theme.color.gray500}
              />
            </View>
          </ScrollView>
        </View>
      )}
      <View
        style={{
          padding: 20,
          borderTopWidth: 1,
          borderTopColor: 'rgba(63, 63, 79, 0.3)',
          zIndex: 2,
        }}
      >
        <Button
          testID="LocationPicker_chooseButton"
          disabled={!places.length}
          onPress={onChoose}
          text={
            places.length
              ? $t(
                  {
                    id: 'LocationPicker_chooseButton',
                    defaultMessage: 'Add {numLocations, plural, one{1 place} other{# places}}',
                  },
                  { numLocations: places.length },
                )
              : $t({ id: 'LocationPicker_chooseButtonDisabled', defaultMessage: 'Add places' })
          }
          alignSelf="center"
        />
      </View>
    </View>
  );
}

export function PlacesPicker(props: StackScreenProps<'PlacesPicker'>) {
  const navigate = props.navigation.navigate;
  const returnRoute = props.route.params.returnRoute;
  const onChoose = useCallback(
    (places: Place[], permissionDenied?: boolean) => {
      navigate({
        name: returnRoute as any, // eslint-disable-line
        params: { _placesPickerResult: { places, permissionDenied } },
        merge: true,
      });
    },
    [navigate, returnRoute],
  );

  const setOptions = props.navigation.setOptions;
  useEffect(() => {
    setOptions({
      title: 'Places',
      headerLeft: ({ tintColor }) => (
        <HeaderBackButton
          onPress={() => {
            onChoose([]);
          }}
          tintColor={tintColor}
        />
      ),
      headerRight: undefined,
    });
  }, [setOptions, onChoose]);

  return (
    <>
      <StatusBar barStyle="dark-content" />
      <LocationPicker
        label={props.route.params?.label ?? 'Places to go that provide distraction'}
        onChoose={onChoose}
      />
    </>
  );
}
