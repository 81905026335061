import { useNavigation, useRoute } from '@react-navigation/native';
import differenceInHours from 'date-fns/differenceInHours';
import { useRef, useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { IntlShape, useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { SuicidalIdeationType } from '@oui/lib/src/types/avro/cssrs';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Breadcrumbs } from '@src/components/Breadcrumbs';
import {
  FormAnswers,
  Schema,
  // getTextSummaryForForm,
  useRenderForm,
} from '@src/components/SchemaForm';
import { Assessment as AssessmentType, useAssessmentByID } from '@src/hooks/useAssessments';
import { usePatient } from '@src/hooks/usePatient';
import { beckSuicide, phq9, scs } from '@src/messages/assessments';
import { CliniciansScreenProps } from '@src/types';

const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

function schemaFormAnswersToNumericDiagnosticResponses(
  schema: Schema,
  data: FormAnswers,
): number[] {
  return Object.keys(schema).map((key) => {
    return (data[key]?.[0]?.value?.[0] as number) ?? undefined;
  });
}

const CSSRS_TO_SCHEMA_MAPPING: Record<string, string> = {
  // behavior
  suicideAttempt: 'suicide_attempt',
  interruptedAttempt: 'interrupted_attempt',
  selfInterruptedAttempt: 'aborted_attempt',
  preparatoryActs: 'preparatory_acts',
  selfHarm: 'self_harm',
  // activatingEvents
  negativeEvent: 'negative_events',
  incarcerationOrHomelessness: 'incarceration_or_homelessness',
  // treatmentHistory
  hopelessness: 'dissatisfied',
  nonCompliance: 'non_compliant',
  noTreatment: 'no_treatment',
};

function schemaFormAnswersToAssessment(
  type: AssessmentType['type'],
  occurrenceDate: string,
  schema: Schema,
  data: FormAnswers,
): AssessmentType {
  if (type === 'CSSRS') {
    const selfHarm = data['self_harm']?.[0];
    const activatingEvents = data['activating_events']?.[0];
    const treatmentHistory = data['treatment_history']?.[0];
    return {
      type,
      occurrenceDate,
      behavior: {
        suicideAttempt: {
          checked: !!selfHarm?.value?.includes('suicide_attempt')?.toString(),
          recent: selfHarm?.followups?.['suicide_attempt']?.[0]?.value?.[0] === 'recent',
          description: selfHarm?.followups?.['suicide_attempt']?.[1]?.value?.[0]?.toString() ?? '',
        },
        interruptedAttempt: {
          checked: !!selfHarm?.value?.includes('interrupted_attempt')?.toString(),
          recent: selfHarm?.followups?.['interrupted_attempt']?.[0]?.value?.[0] === 'recent',
          description:
            selfHarm?.followups?.['interrupted_attempt']?.[1]?.value?.[0]?.toString() ?? '',
        },
        selfInterruptedAttempt: {
          checked: !!selfHarm?.value?.includes('aborted_attempt')?.toString(),
          recent: selfHarm?.followups?.['aborted_attempt']?.[0]?.value?.[0] === 'recent',
          description: selfHarm?.followups?.['aborted_attempt']?.[1]?.value?.[0]?.toString() ?? '',
        },
        preparatoryActs: {
          checked: !!selfHarm?.value?.includes('preparatory_acts')?.toString(),
          recent: selfHarm?.followups?.['preparatory_acts']?.[0]?.value?.[0] === 'recent',
          description: selfHarm?.followups?.['preparatory_acts']?.[1]?.value?.[0]?.toString() ?? '',
        },
        selfHarm: {
          checked: !!selfHarm?.value?.includes('self_harm')?.toString(),
          recent: selfHarm?.followups?.['self_harm']?.[0]?.value?.[0] === 'recent',
          description: selfHarm?.followups?.['self_harm']?.[1]?.value?.[0]?.toString() ?? '',
        },
      },
      mostSevereIdeation:
        (data['suicidal_thoughts']?.[0]?.value?.[0] as SuicidalIdeationType) ?? 'NON_DISCLOSE',
      activatingEvents: {
        negativeEvent: {
          checked: !!activatingEvents?.value?.includes('negative_events')?.toString(),
          recent: false,
          description:
            activatingEvents?.followups?.['negative_events']?.[0]?.value?.[0]?.toString() ?? '',
        },
        incarcerationOrHomelessness: {
          checked: !!activatingEvents?.value?.includes('incarceration_or_homelessness')?.toString(),
          recent: false,
          description:
            activatingEvents?.followups?.[
              'incarceration_or_homelessness'
            ]?.[0]?.value?.[0]?.toString() ?? '',
        },
        loneliness: {
          checked: !!activatingEvents?.value?.includes('loneliness')?.toString(),
          recent: false,
          description:
            activatingEvents?.followups?.['loneliness']?.[0]?.value?.[0]?.toString() ?? '',
        },
      },
      treatmentHistory: {
        psychiatric: {
          checked: !!treatmentHistory?.value?.includes('psychiatric')?.toString(),
          recent: false,
          description:
            treatmentHistory?.followups?.['psychiatric']?.[0]?.value?.[0]?.toString() ?? '',
        },
        hopelessness: {
          checked: !!treatmentHistory?.value?.includes('dissatisfied')?.toString(),
          recent: false,
          description:
            treatmentHistory?.followups?.['dissatisfied']?.[0]?.value?.[0]?.toString() ?? '',
        },
        nonCompliance: {
          checked: !!treatmentHistory?.value?.includes('non_compliant')?.toString(),
          recent: false,
          description:
            treatmentHistory?.followups?.['non_compliant']?.[0]?.value?.[0]?.toString() ?? '',
        },
        noTreatment: {
          checked: !!treatmentHistory?.value?.includes('no_treatment')?.toString(),
          recent: false,
          description:
            treatmentHistory?.followups?.['no_treatment']?.[0]?.value?.[0]?.toString() ?? '',
        },
      },
      otherRiskFactors: (data['other_risk_factors']?.[0]?.value as string[]) ?? [],
      clinicalStatus: (data['clinical_status']?.[0]?.value as string[]) ?? [],
      protectiveFactors: (data['protective_factors']?.[0]?.value as string[]) ?? [],
      otherProtectiveFactors: (data['other_protective_factors']?.[0]?.value as string[]) ?? [],
    };
  }

  return {
    type,
    occurrenceDate,
    responses: schemaFormAnswersToNumericDiagnosticResponses(schema, data),
  };
}

function numericDiagnosticResponsesToSchemaFormAnswers(responses: number[]): FormAnswers {
  const isShortBecks = responses.length === 7;
  return responses.reduce<FormAnswers>((carry, value, index) => {
    if (isShortBecks && index > 4) index = index + 14;
    carry[index] = [{ value: [value] }];
    return carry;
  }, {} as FormAnswers);
}

function cssrsToSchemaFormAnswers(assessment: AssessmentType): FormAnswers {
  if (assessment.type !== 'CSSRS') return {};
  return {
    self_harm: [
      {
        value: Object.entries(assessment.behavior)
          .filter(([key, value]) => {
            return value.checked;
          })
          .map(([key]) => CSSRS_TO_SCHEMA_MAPPING[key] ?? key),
        followups: Object.entries(assessment.behavior).reduce<FormAnswers>(
          (carry, [key, value]) => {
            carry[CSSRS_TO_SCHEMA_MAPPING[key] ?? key] = [
              { value: [value.recent ? 'recent' : 'not_recent'] },
              { value: [value.description] },
            ];
            return carry;
          },
          {},
        ),
      },
    ],
    suicidal_thoughts: [{ value: [assessment.mostSevereIdeation] }],
    activating_events: [
      {
        value: Object.entries(assessment.activatingEvents)
          .filter(([key, value]) => {
            return value.checked;
          })
          .map(([key]) => CSSRS_TO_SCHEMA_MAPPING[key] ?? key),
        followups: Object.entries(assessment.activatingEvents).reduce<FormAnswers>(
          (carry, [key, value]) => {
            carry[CSSRS_TO_SCHEMA_MAPPING[key] ?? key] = [{ value: [value.description] }];
            return carry;
          },
          {},
        ),
      },
    ],
    treatment_history: [
      {
        value: Object.entries(assessment.treatmentHistory)
          .filter(([key, value]) => {
            return value.checked;
          })
          .map(([key]) => CSSRS_TO_SCHEMA_MAPPING[key] ?? key),
        followups: Object.entries(assessment.treatmentHistory).reduce<FormAnswers>(
          (carry, [key, value]) => {
            carry[CSSRS_TO_SCHEMA_MAPPING[key] ?? key] = [{ value: [value.description] }];
            return carry;
          },
          {},
        ),
      },
    ],
    other_risk_factors: [{ value: assessment.otherRiskFactors }],
    clinical_status: [{ value: assessment.clinicalStatus }],
    protective_factors: [{ value: assessment.protectiveFactors }],
    other_protective_factors: [{ value: assessment.otherProtectiveFactors }],
  };
}

function assessmentToSchemaFormAnswers(assessment: AssessmentType): FormAnswers {
  if (!assessment.occurrenceDate) return {};
  return assessment.type === 'CSSRS'
    ? cssrsToSchemaFormAnswers(assessment)
    : numericDiagnosticResponsesToSchemaFormAnswers(assessment.responses);
}

function getBeckSuicideSchema($t: IntlShape['$t'], data: FormAnswers) {
  const question3Answer = data[3]?.[0]?.value?.[0] as number | undefined;
  const question4Answer = data[4]?.[0]?.value?.[0] as number | undefined;
  const showMiddleQuestions = (question3Answer ?? 0) + (question4Answer ?? 0) > 0;
  const showEndQuestions =
    typeof question3Answer === 'number' && typeof question4Answer === 'number';
  const schema: Schema = {};
  for (let i = 0; i < 21; i++) {
    if (i > 4 && i < 19 && !showMiddleQuestions) continue;
    if (i > 4 && !showEndQuestions) continue;
    schema[i] = {
      type: 'singlechoice',
      label: ' ',
      choices: [
        { label: $t(beckSuicide[`question${i}_choice0` as keyof typeof beckSuicide]), value: 0 },
        { label: $t(beckSuicide[`question${i}_choice1` as keyof typeof beckSuicide]), value: 1 },
        { label: $t(beckSuicide[`question${i}_choice2` as keyof typeof beckSuicide]), value: 2 },
      ],
    };
  }
  return schema;
}

function getSCSSchema($t: IntlShape['$t']) {
  const choices = [
    { label: $t(scs.choice0), value: 1 },
    { label: $t(scs.choice1), value: 2 },
    { label: $t(scs.choice2), value: 3 },
    { label: $t(scs.choice3), value: 4 },
    { label: $t(scs.choice4), value: 5 },
  ];

  const schema: Schema = {
    0: {
      type: 'singlechoice',
      label: $t(scs.question0),
      choices,
    },
    1: {
      type: 'singlechoice',
      label: $t(scs.question1),
      choices,
    },
    2: {
      type: 'singlechoice',
      label: $t(scs.question2),
      choices,
    },
    3: {
      type: 'singlechoice',
      label: $t(scs.question3),
      choices,
    },
    4: {
      type: 'singlechoice',
      label: $t(scs.question4),
      choices,
    },
    5: {
      type: 'singlechoice',
      label: $t(scs.question5),
      choices,
    },
    6: {
      type: 'singlechoice',
      label: $t(scs.question6),
      choices,
    },
    7: {
      type: 'singlechoice',
      label: $t(scs.question7),
      choices,
    },
    8: {
      type: 'singlechoice',
      label: $t(scs.question8),
      choices,
    },
    9: {
      type: 'singlechoice',
      label: $t(scs.question9),
      choices,
    },
    10: {
      type: 'singlechoice',
      label: $t(scs.question10),
      choices,
    },
    11: {
      type: 'singlechoice',
      label: $t(scs.question11),
      choices,
    },
    12: {
      type: 'singlechoice',
      label: $t(scs.question12),
      choices,
    },
    13: {
      type: 'singlechoice',
      label: $t(scs.question13),
      choices,
    },
    14: {
      type: 'singlechoice',
      label: $t(scs.question14),
      choices,
    },
    15: {
      type: 'singlechoice',
      label: $t(scs.question15),
      choices,
    },
    16: {
      type: 'singlechoice',
      label: $t(scs.question16),
      choices,
    },
    17: {
      type: 'singlechoice',
      label: $t(scs.question17),
      choices,
    },
  };
  return schema;
}
function getBSCSSchema($t: IntlShape['$t']) {
  const choices = [
    { label: $t(scs.choice0), value: 1 },
    { label: $t(scs.choice1), value: 2 },
    { label: $t(scs.choice2), value: 3 },
    { label: $t(scs.choice3), value: 4 },
    { label: $t(scs.choice4), value: 5 },
  ];

  const schema: Schema = {
    0: {
      type: 'singlechoice',
      label: $t(scs.question8),
      choices,
    },
    1: {
      type: 'singlechoice',
      label: $t(scs.question9),
      choices,
    },
    2: {
      type: 'singlechoice',
      label: $t(scs.question11),
      choices,
    },
    3: {
      type: 'singlechoice',
      label: $t(scs.question12),
      choices,
    },
    4: {
      type: 'singlechoice',
      label: $t(scs.question13),
      choices,
    },
    5: {
      type: 'singlechoice',
      label: $t(scs.question14),
      choices,
    },
  };
  return schema;
}

function getPHQ9Schema($t: IntlShape['$t']) {
  const choices = [
    { label: $t(phq9.choice0), value: 0 },
    { label: $t(phq9.choice1), value: 1 },
    { label: $t(phq9.choice2), value: 2 },
    { label: $t(phq9.choice3), value: 3 },
  ];

  const schema: Schema = {
    0: {
      type: 'singlechoice',
      label: $t(phq9.question0),
      choices,
    },
    1: {
      type: 'singlechoice',
      label: $t(phq9.question1),
      choices,
    },
    2: {
      type: 'singlechoice',
      label: $t(phq9.question2),
      choices,
    },
    3: {
      type: 'singlechoice',
      label: $t(phq9.question3),
      choices,
    },
    4: {
      type: 'singlechoice',
      label: $t(phq9.question4),
      choices,
    },
    5: {
      type: 'singlechoice',
      label: $t(phq9.question5),
      choices,
    },
    6: {
      type: 'singlechoice',
      label: $t(phq9.question6),
      choices,
    },
    7: {
      type: 'singlechoice',
      label: $t(phq9.question7),
      choices,
    },
    8: {
      type: 'singlechoice',
      label: $t(phq9.question8),
      choices,
    },
  };
  return schema;
}

export function getCSSRSSchema($t: IntlShape['$t']) {
  const RECENCY_FOLLOWUPS = [
    {
      type: 'singlechoice' as const,
      ui: { horizontal: true },
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_recent',
            defaultMessage: 'In the past 3 months',
          }),
          value: 'recent',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_notRecent',
            defaultMessage: 'More than 3 months ago',
          }),
          value: 'not_recent',
        },
      ],
    },
    {
      type: 'text' as const,
      placeholder: $t({
        id: 'Assessment_cssrs_selfHarm_describeBehavior',
        defaultMessage: 'Describe the behavior (include dates)',
      }),
    },
  ];
  const schema: Schema = {
    self_harm: {
      type: 'multichoice',
      label: $t({
        id: 'Assessment_cssrs_selfHarm_label',
        defaultMessage: 'Suicidal and Self-Injurious Behavior',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_selfHarm_description',
        defaultMessage: 'Have you ever had (check all that apply)',
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_choices_suicideAttempt',
            defaultMessage: 'Actual suicide attempt',
          }),
          value: 'suicide_attempt',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_choices_interruptedAttempt',
            defaultMessage: 'Interrupted attempt',
          }),
          value: 'interrupted_attempt',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_choices_abortedAttempt',
            defaultMessage: 'Aborted or Self-Interrupted attempt',
          }),
          value: 'aborted_attempt',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_choices_preparatoryActs',
            defaultMessage: 'Other preparatory acts to kill self',
          }),
          value: 'preparatory_acts',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_selfHarm_choices_selfHarm',
            defaultMessage: 'Self-injurious behavior without suicidal intent',
          }),
          value: 'self_harm',
        },
      ],
      followups: {
        suicide_attempt: RECENCY_FOLLOWUPS,
        interrupted_attempt: RECENCY_FOLLOWUPS,
        aborted_attempt: RECENCY_FOLLOWUPS,
        preparatory_acts: RECENCY_FOLLOWUPS,
        self_harm: RECENCY_FOLLOWUPS,
      },
    },
    suicidal_thoughts: {
      type: 'singlechoice',
      label: $t({
        id: 'Assessment_cssrs_suicidalThoughts_label',
        defaultMessage: 'Suicidal Ideation',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_suicidalThoughts_description',
        defaultMessage:
          "From this list, check the one (the most severe) thought you've had in the last month",
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_suicidalThoughts_choices_desire',
            defaultMessage: 'Wish to be dead',
          }),
          value: 'WISH_TO_BE_DEAD',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_suicidalThoughts_choices_thoughts',
            defaultMessage: 'Suicidal thoughts',
          }),
          value: 'GENERAL_SUICIDAL_IDEATION',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_suicidalThoughts_choices_thoughtsWithMethod',
            defaultMessage:
              'Suicidal thoughts with method (but without specific plan or intent to act)',
          }),
          value: 'IDEATION_WITH_METHOD_WITHOUT_INTENT',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_suicidalThoughts_choices_intentWithoutPlan',
            defaultMessage: 'Suicidal intent (without specific plan)',
          }),
          value: 'IDEATION_WITH_INTENT_WITHOUT_PLAN',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_suicidalThoughts_choices_intentWithPlan',
            defaultMessage: 'Suicidal intent with specific plan',
          }),
          value: 'IDEATION_WITH_PLAN_WITH_INTENT',
        },
      ],
    },
    activating_events: {
      type: 'multichoice',
      label: $t({
        id: 'Assessment_cssrs_activatingEvents_label',
        defaultMessage: 'Recent Activating Events',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_activatingEvents_description',
        defaultMessage: 'Check all that apply',
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_activatingEvents_choices_negativeEvents',
            defaultMessage:
              'Recent loss(es) or other significant negative event(s) (legal, financial, relationship, etc.)',
          }),
          value: 'negative_events',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_activatingEvents_choices_incarcerationOrHomelessness',
            defaultMessage: 'Pending incarceration or homelessness',
          }),
          value: 'incarceration_or_homelessness',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_activatingEvents_choices_loneliness',
            defaultMessage: 'Current or pending isolation or feeling alone',
          }),
          value: 'loneliness',
        },
      ],
      followups: {
        negative_events: [
          {
            type: 'text',
            placeholder: $t({
              id: 'Assessment_cssrs_activatingEvents_negativeEventsDescription',
              defaultMessage: 'Describe recent negative event',
            }),
          },
        ],
      },
    },
    treatment_history: {
      type: 'multichoice',
      label: $t({
        id: 'Assessment_cssrs_treatmentHistory_label',
        defaultMessage: 'Treatment History',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_treatmentHistory_description',
        defaultMessage: 'Check all that apply',
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_treatmentHistory_choices_psychiatric',
            defaultMessage: 'Previous psychiatric diagnoses and treatments',
          }),
          value: 'psychiatric',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_treatmentHistory_choices_dissatisfied',
            defaultMessage: 'Hopelessness or dissatisfied with treatment',
          }),
          value: 'dissatisfied',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_treatmentHistory_choices_nonCompliant',
            defaultMessage: 'Non-compliant with treatment',
          }),
          value: 'non_compliant',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_treatmentHistory_choices_noTreatment',
            defaultMessage: 'Not receiving treatment',
          }),
          value: 'no_treatment',
        },
      ],
      followups: {
        psychiatric: [
          {
            type: 'text',
            placeholder: $t({
              id: 'Assessment_cssrs_treatmentHistory_psychiatricFollowup',
              defaultMessage: 'What was the diagnosis or treatment?',
            }),
          },
        ],
      },
    },
    other_risk_factors: {
      type: 'text',
      label: $t({
        id: 'Assessment_cssrs_otherRiskFactors_label',
        defaultMessage: 'Other Risk Factors',
      }),
      other: true,
    },
    clinical_status: {
      type: 'multichoice',
      label: $t({
        id: 'Assessment_cssrs_clinicalStatus_label',
        defaultMessage: 'Recent Clinical Status',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_clinicalStatus_description',
        defaultMessage: 'Check all that apply',
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_hopelessness',
            defaultMessage: 'Hopelessness',
          }),
          value: 'hopelessness',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_depressiveEpisode',
            defaultMessage: 'Major depressive episode',
          }),
          value: 'depressive_episode',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_affectiveEpisode',
            defaultMessage: 'Mixed affective episode (e.g. Bipolar)',
          }),
          value: 'affective_episode',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_hallucinations',
            defaultMessage: 'Command hallucinations to hurt self',
          }),
          value: 'hallucinations',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_impulsiveBehavior',
            defaultMessage: 'Highly impulsive behavior',
          }),
          value: 'impulsive_behavior',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_substanceAbuse',
            defaultMessage: 'Substance abuse or dependence',
          }),
          value: 'substance_abuse',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_anxiety',
            defaultMessage: 'Agitation or severe anxiety',
          }),
          value: 'anxiety',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_perceivedBurden',
            defaultMessage: 'Perceived burden on family or others',
          }),
          value: 'perceived_burden',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_acuteMedicalProblem',
            defaultMessage:
              'Chronic physical pain or other acute medical problem (HIV/AIDS, COPD, cancer, etc.)',
          }),
          value: 'acute_medical_problem',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_homicidal',
            defaultMessage: 'Homicidal ideation',
          }),
          value: 'homicidal',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_aggressive',
            defaultMessage: 'Aggressive behavior towards others',
          }),
          value: 'aggressive',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_suicideMethodAvailable',
            defaultMessage: 'Method for suicide available (gun, pills, etc.)',
          }),
          value: 'suicide_method_available',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_refuseSafetyPlan',
            defaultMessage: 'Refuses or feels unable to agree to safety plan',
          }),
          value: 'refuse_safety_plan',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_sexualAbuse',
            defaultMessage: 'Sexual abuse (lifetime)',
          }),
          value: 'sexual_abuse',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_clinicalStatus_choices_familyHistoryOfSuicide',
            defaultMessage: 'Family history of suicide (lifetime)',
          }),
          value: 'family_history_of_suicide',
        },
      ],
    },
    protective_factors: {
      type: 'multichoice',
      label: $t({
        id: 'Assessment_cssrs_protectiveFactors_label',
        defaultMessage: 'Recent Protective Factors',
      }),
      sublabel: $t({
        id: 'Assessment_cssrs_protectiveFactors_description',
        defaultMessage: 'Check all that apply',
      }),
      choices: [
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_reasonsForLiving',
            defaultMessage: 'Identifies reasons for living',
          }),
          value: 'reasons_for_living',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_familyResponsibility',
            defaultMessage: 'Responsibility to family or others; living with family',
          }),
          value: 'family_responsibility',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_supportNetwork',
            defaultMessage: 'Supportive social network or family',
          }),
          value: 'support_network',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_fearOfDeath',
            defaultMessage: 'Fear of death or dying due to pain and suffering',
          }),
          value: 'fear_of_death',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_immoralBelief',
            defaultMessage: 'Belief that suicide is immoral; high spirituality',
          }),
          value: 'immoral_belief',
        },
        {
          label: $t({
            id: 'Assessment_cssrs_protectiveFactors_choices_engagedInActivity',
            defaultMessage: 'Engaged in work or school',
          }),
          value: 'engaged_in_activity',
        },
      ],
    },
    other_protective_factors: {
      type: 'text',
      label: $t({
        id: 'Assessment_cssrs_otherProtectiveFactors_label',
        defaultMessage: 'Other Protective Factors',
      }),
      other: true,
    },
  };
  return schema;
}

function isWithin24Hours(dateStr: string) {
  const result = differenceInHours(new Date(), parseGQLDateTime(dateStr));
  return result < 24;
}

export function Assessment() {
  const { navigate } = useNavigation<CliniciansScreenProps<'Assessment'>['navigation']>();
  const { params } = useRoute<CliniciansScreenProps<'Assessment'>['route']>();
  const { data: patientData } = usePatient(params.patientID);
  const { update, data } = useAssessmentByID({ compositionID: params.compositionID });
  const { theme } = useTheme();
  const printRef = useRef(null);
  const [error, setError] = useState('');

  const patient = patientData?.patientByPatientID?.patient;
  const preferred = patient?.person.givenName;
  const fullName = patient ? `${preferred} ${patient.person.familyName}` : '';
  const { $t } = useI18n();

  const type = data?.assessment.type ?? 'UNKNOWN';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SCHEMA_FNS: Record<AssessmentType['type'], ($t: IntlShape['$t'], data: any) => Schema> = {
    UNKNOWN: () => ({}),
    CSSRS: getCSSRSSchema,
    SCS: getSCSSchema,
    BSCS: getBSCSSchema,
    PHQ9: getPHQ9Schema,
    BECK: getBeckSuicideSchema,
  };

  const isEditable =
    !data ||
    data.assessment.occurrenceDate === '' ||
    isWithin24Hours(data.assessment.occurrenceDate);

  const defaultValue = data?.assessment ? assessmentToSchemaFormAnswers(data.assessment) : {};
  const { data: formData, form } = useRenderForm((d) => SCHEMA_FNS[type]($t, d), defaultValue);

  return (
    <View style={{ flexDirection: 'row', flex: 1 }} testID="Patient">
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, padding: 20, backgroundColor: theme.color.gray800 }}
      >
        <View spacing={24}>
          <Breadcrumbs
            crumbs={[
              { label: 'Patients', to: 'patients' },
              {
                label: fullName || 'Patient',
                to: 'patients/:patientID',
                params: { patientID: params.patientID },
              },
              { label: 'Questionnaire', to: '' },
            ]}
          />
          <View spacing={20} style={{ alignItems: 'center', padding: 20 }}>
            <View
              style={[
                card,
                {
                  maxWidth: 800,
                  width: '100%',
                  alignSelf: 'center',
                  justifyContent: 'space-between',
                  borderRadius: 20,
                  overflow: 'hidden',
                },
              ]}
              ref={printRef}
            >
              {data ? (
                <View style={[{ width: '100%', alignSelf: 'center', padding: 40 }]} spacing={24}>
                  {type === 'PHQ9' ? (
                    <Text text="Over the last 2 weeks, how often have you been bothered by any of the following problems?" />
                  ) : type === 'SCS' ? (
                    <Text text="The following 18 statements are intended to assess your beliefs about your current problems. Please read each statement carefully and circle the number that best describes how you feel right now." />
                  ) : type === 'BSCS' ? (
                    <Text text="The following 6 statements are intended to assess your beliefs about your current problems. Please read each statement carefully and circle the number that best describes how you feel right now." />
                  ) : type === 'BECK' ? (
                    <Text text="Choose the one statement in each group that best describes how you have been feeling for the past week, including today. Be sure to read all of the statements in each group before making a choice." />
                  ) : null}
                  <ErrorPresenter errorString={error} />
                  {form}
                  <View row style={{ marginTop: 80, justifyContent: 'space-between' }}>
                    <Button
                      disabled={!isEditable}
                      variant="solid"
                      text="Done"
                      onPress={async () => {
                        setError('');
                        await sleep(1); // allow new error value to re-render form
                        const newData = schemaFormAnswersToAssessment(
                          type,
                          data.assessment.occurrenceDate || new Date().toISOString(),
                          SCHEMA_FNS[type]($t, formData),
                          formData,
                        );

                        if (newData.type !== 'CSSRS') {
                          const valid =
                            newData.responses.findIndex((v) => typeof v !== 'number') === -1;
                          if (!valid) {
                            setError('You must answer all questions before submitting.');
                            return;
                          }
                        }

                        await update(newData);
                        navigate('Patient', {
                          patientID: params.patientID,
                          anchor: 'questionnaires',
                        });
                      }}
                      testID="Assessment_doneButton"
                    />
                    <Button
                      variant="text"
                      icon="download"
                      text="Download form"
                      onPress={async () => {
                        const { pdfFromNodes } = await import('@src/lib/pdf');
                        const pdf = await pdfFromNodes([printRef.current]);
                        pdf.download(`${type}_${Date.now()}.pdf`);
                        await sleep(2000);
                      }}
                    />
                  </View>
                </View>
              ) : (
                <View style={[{ width: '100%', alignSelf: 'center', padding: 20 }]} spacing={24}>
                  <ActivityIndicator />
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
